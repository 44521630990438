import React, { useState, useRef, useEffect } from "react";
import logo from "../../../assets/images/event/logo.jpg";
import logoutIcon from "../../../assets/images/event/logout.png";
import userIcon from "../../../assets/images/event/user-icon.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Header = ({
  title,
  handleFirstBtn,
  handleSecondBtn,
  firstBtnName,
  secondBtnName,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const history = useHistory();

  const [username, setUsername] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    history.push("/event/login");
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("userName");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  return (
    <div>
      <header
        style={{
          backgroundColor: "#fff",
          padding: "10px 20px",
          textAlign: "left",
          margin: 0,
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: "80px", marginRight: "10px" }}
        />
      </header>
      <header className="user-list-header">
        <h1>{title}</h1>

        <nav className="ctm-nav-bar">
          <button onClick={handleFirstBtn} className="navBar-btn-1">
            {firstBtnName}
          </button>
          <button onClick={handleSecondBtn} className="navBar-btn-2">
            {secondBtnName}
          </button>
        </nav>
        <div className="right-user-sec">
          <button className="user-icon" onClick={togglePopup}>
            <img className="w-8 mr-3" src={userIcon} alt="User" />
          </button>
          <button className="user-icon" onClick={handleLogout}>
            <img className="w-9" src={logoutIcon} alt="Logout" />
          </button>

          {showPopup && (
            <div className="custom-popup" ref={popupRef}>
              <div className="header-user-action">
                <p>Hi, {username}!</p>
              </div>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
