import React, { useEffect, useState } from "react";
import { getWinnerData } from "../QRScanner/services/qrService";
import { enqueueSnackbar } from "notistack";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import "./utils/common/qr.scss";
import Header from "./Component/Header";
import { STATUS_MESSAGE } from "./utils/constants/constants";

const WinnerList = () => {
  const [winnerData, setWinnerData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchWinnerData();
  }, []);
  const fetchWinnerData = async () => {
    try {
      setLoading(true);
      const response = await getWinnerData({
        page: 1,
        limit: 1000,
        orderBy: "ASC",
        orderName: "",
        search: "",
        all_records: false,
      });
      const data = response.data;
      if (
        data.status === STATUS_MESSAGE.SUCCESS &&
        data.statusCode === 200 &&
        data.data?.users_data?.length > 0
      ) {
        setWinnerData(data.data.users_data);
      } else {
        setWinnerData("");
        throw new Error(
          response?.message || STATUS_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      enqueueSnackbar(error?.message || STATUS_MESSAGE.SOMETHING_WENT_WRONG, {
        variant: STATUS_MESSAGE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLimitChange = (e) => {
    setPageLimit(e);
  };

  const handlePageChange = (e) => {
    setPageNum(e);
  };

  const handleSort = (column, sortDirection) => {
    if (column && column.key) {
      if (column.sortFunction) {
        setSortColumn(column.sortKey);
        setSortDirection(sortDirection);
        setWinnerData((prevData) => {
          const sortedData = [...prevData].sort(
            (a, b) =>
              column.sortFunction(a, b) * (sortDirection === "asc" ? 1 : -1)
          );
          return sortedData;
        });
      } else {
        setSortColumn(column.key);
        setSortDirection(sortDirection);
      }
    }
  };
  const handleQRScanner = () => {
    history.push("/event/qr-scanner");
  };
  const handleUserlist = () => {
    history.push("/event/user-list");
  };
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1 + (pageNum - 1) * pageLimit,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.user_name,
      sortable: true,
      key: "user_name",
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
      key: "company_name",
    },
    {
      name: "Address",
      selector: (row) => row.user_address,
      sortable: true,
      key: "user_address",
    },
    {
      name: "Phone Number",
      selector: (row) => {
        const phone = row.phone ? String(row.phone) : "";
        if (phone.length > 5) {
          return `*****${phone.slice(-5)}`;
        }
        return phone;
      },
      sortable: false,
      key: "phone",
    },
    {
      name: "Pavillion Details",
      selector: (row) => row.pavillion_details,
      sortable: true,
      key: "pavillion_details",
    },
    {
      name: "Seat Number",
      selector: (row) => (row.seat_no ? Number(row.seat_no) : ""),
      sortable: true,
      key: "seat_no",
    },
  ];
  // Custom styles for DataTable to add borders
  const customStyles = {
    cells: {
      style: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
      },
    },
  };
  return (
    <div>
      <Header
        title={"Winner List"}
        handleFirstBtn={handleQRScanner}
        handleSecondBtn={handleUserlist}
        firstBtnName={"QR Code Scanner"}
        secondBtnName={"User List"}
      />

      <div className="event-user-list">
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={winnerData}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            onSort={handleSort}
            customStyles={customStyles}
            className="omni-datatable"
          />
        )}
      </div>
    </div>
  );
};

export default WinnerList;
