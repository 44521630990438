import React, { useEffect, useRef, useState } from "react";
import jsQR from "jsqr";
import { checkinVisitors } from "../QRScanner/services/qrService";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Header from "./Component/Header";
import { STATUS_MESSAGE } from "./utils/constants/constants";

const QRScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [visitorData, setVisitorData] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [qrLoading, setQRLoading] = useState(true);
  const [message, setMessage] = useState("");
  const streamRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    console.log("cccsss");
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const startScanning = () => {
    setMessage("Scanning... Please wait");
    setQRLoading(true);
    setVisitorData(null);

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((userMediaStream) => {
        if (videoRef.current) {
          streamRef.current = userMediaStream;
          videoRef.current.srcObject = streamRef.current;
          videoRef.current.setAttribute("playsinline", true);

          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
            setQRLoading(false);
            setScanning(true);
            setMessage("Scanning... Please wait");

            if (canvasRef.current) {
              const canvas = canvasRef.current;
              const context = canvas.getContext("2d");
              canvas.width = videoRef.current.videoWidth;
              canvas.height = videoRef.current.videoHeight;
            }

            requestAnimationFrame(scanQRCode);
          };
        }
      })
      .catch((err) => {
        setQRLoading(false);
        setMessage("Failed to access camera");
      });
  };

  const stopScanning = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setScanning(false);
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const scanQRCode = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (
      !videoRef.current ||
      !videoRef.current.videoWidth ||
      !videoRef.current.videoHeight
    ) {
      requestAnimationFrame(scanQRCode);
      return;
    }

    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, canvas.width, canvas.height);

    if (code) {
      const data = decodeQRData(code.data);
      if (data) {
        if (data.type === "url") {
          sendCheckInDataToServer(data);
        } else if (data.type === "text") {
          sendCheckInDataToServer(data);
        } else {
          sendCheckInDataToServer(data);
        }
        setMessage("Scan completed!");
        stopScanning();
      }
    } else {
      requestAnimationFrame(scanQRCode);
    }
  };

  const decodeQRData = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      if (data.startsWith("http://") || data.startsWith("https://")) {
        return { type: "url", url: data };
      } else {
        const parts = data.split(",").map((part) => part.trim());

        if (parts.length >= 5) {
          const id = parts[0];
          const name = parts.slice(1, parts.length - 3).join(" ");
          const company_name = parts[parts.length - 3];
          const pavillion_details = parts[parts.length - 2];
          const seat = parts[parts.length - 1];

          return {
            type: "visitor",
            id,
            name,
            company_name,
            pavillion_details,
            seat,
          };
        } else {
          return { type: "text", text: data };
        }
      }
    }
  };

  const displayVisitorDetails = (data) => {
    setVisitorData(data);
    setMessage("Scan completed!");
  };

  const sendCheckInDataToServer = async (visitorData) => {
    try {
      const response = await checkinVisitors({
        reference_number: visitorData.id,
      });
      const data = response.data;
      if (data.status === STATUS_MESSAGE.SUCCESS && data.statusCode === 200) {
        displayVisitorDetails(visitorData);
        enqueueSnackbar(STATUS_MESSAGE.CHECKIN_SUCCESS, {
          variant: STATUS_MESSAGE.SUCCESS,
        });
      } else {
        throw new Error(
          response?.message || STATUS_MESSAGE.SOMETHING_WENT_WRONG
        );
      }
    } catch (error) {
      setVisitorData(null);
      enqueueSnackbar(
        error?.response?.data?.message || STATUS_MESSAGE.SOMETHING_WENT_WRONG,
        {
          variant: STATUS_MESSAGE.ERROR,
        }
      );
    }
  };

  const handleUserlist = () => {
    history.push("/event/user-list");
  };
  const handleWinnerlist = () => {
    history.push("/event/winner-list");
  };
  return (
    <div>
      <Header
        title={"QR Code Scanner"}
        handleFirstBtn={handleUserlist}
        handleSecondBtn={handleWinnerlist}
        firstBtnName={"User List"}
        secondBtnName={"Winner List"}
      />

      <div className="qr-code-event">
        <h2>{message}</h2>

        <div>
          {!visitorData && (
            <div
              style={{
                display: qrLoading ? "none" : "inline-block",
                border: "3px solid #ccc",
                padding: "10px",
                borderRadius: "8px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <video
                ref={videoRef}
                width="320"
                height="240"
                autoPlay
                style={{
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: qrLoading ? "none" : "block",
                }}
              ></video>

              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  height: "60%",
                  border: "2px dashed #ff0000",
                  pointerEvents: "none",
                }}
              />
            </div>
          )}

          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

          <div style={{ marginTop: "20px" }}>
            {!scanning ? (
              <button
                onClick={startScanning}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Start Scanning
              </button>
            ) : (
              <button
                onClick={stopScanning}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Stop Scanning
              </button>
            )}
          </div>

          {visitorData && (
            <div id="visitor-info" style={{ marginTop: "20px" }}>
              <p
                id="visitor-name"
                style={{ fontSize: "1.5em", fontWeight: "bold" }}
              >
                Welcome, {visitorData.name}
              </p>
              <table
                style={{
                  width: "60%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th className="qr-scanner-header">Company Name</th>
                    <th className="qr-scanner-header">Pavilion Details</th>
                    <th className="qr-scanner-header">Seat Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="qr-scanner-row">
                      {visitorData.company_name}
                    </td>
                    <td className="qr-scanner-row">
                      {visitorData.pavillion_details}
                    </td>
                    <td className="qr-scanner-row">{visitorData.seat}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QRScanner;
