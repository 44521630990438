import { BASE_URL } from "../../../../environment";

export const API_BASE_URL = "https://dev.cdc.bpcl.in/cdc/";
export const ENCRYPTION_KEYS = {
  SECRET_KEY: "bpcleventsecretkey",
  TRUE_ENC: "U2FsdGVkX1+9hWXzt3hqxk+aH3vJh7EgFjkkmZtk7o0=",
  FALSE_ENC: "U2FsdGVkX19l5o+dvDVQsgwouUbLWNaViAtTH5+ykQk=",
};
export const API_ROUTE = {
  LOGIN: {
    LOGIN_USER: `${API_BASE_URL}api/bpcl/event-user/login`,
  },
  CHECKIN: {
    CHECKIN_VISITORS: `${API_BASE_URL}api/bpcl/event-user/checkin`,
    EVENT_LIST: `${API_BASE_URL}api/bpcl/event-user/event-list`,
    EVENT_EXPORT: `${API_BASE_URL}api/bpcl/event-user/export`,
    WINNER_LIST: `${API_BASE_URL}api/bpcl/event-user/winners`,
  },
};

export const STATUS_MESSAGE = {
  SUCCESS: "success",
  ERROR: "error",
  SOMETHING_WENT_WRONG: "Something went wrong",
  CHECKIN_SUCCESS: "Checkin successfully",
};
